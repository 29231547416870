<template>
	<div class="section">
		<div class="container" v-if="loading">
			<loading></loading>
		</div>
		<div class="container" v-else>
			<div class="level">
				<div class="level-left">
					<h2 class="title is-2">{{ typeInfo.Name }}</h2>
				</div>
				<div class="level-right">
					<o-field>
						<o-switch v-model="typeInfo.IsDeleted">Deleted</o-switch>
						<template v-slot:message>
							<span v-show="typeInfo.IsDeleted" style="color: indianred">
								This will not show on the rfq.
							</span>
						</template>
					</o-field>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<o-field label="Name">
						<o-input v-model="typeInfo.Name" maxlength="128"></o-input>
					</o-field>
					<o-field label="Grille Type">
						<o-select v-model="typeInfo.GrilleTypeId" placeholder="Select a grille type">
							<option v-for="option in options.grilleTypes" :key="option.Id" :value="option.Id">
								{{ option.Name }}
								<span v-if="option.IsDeleted">&nbsp;(Deleted)</span>
							</option>
						</o-select>
					</o-field>
					<o-field label="Pattern" v-if="isPerforated && options.patterns">
						<o-select v-model="patternId" placeholder="None" :disabled="true">
							<option :key="undefined" :value="undefined">None</option>
							<option v-for="option in options.patterns" :key="option.Id" :value="option.Id">
								{{ option.Name }}
							</option>
						</o-select>
						<template v-slot:message>
							<p>
								To assign a pattern visit
								<router-link :to="{ name: 'adminGrillePatternTypes' }">pattern manager</router-link>
								.
							</p>
						</template>
					</o-field>
					<o-field label="Shopify Product ID">
						{{ typeInfo.ProductId }}
					</o-field>
				</div>
				<div class="column">
					<o-field label="Image">
						<o-upload
							v-model="file"
							drag-drop
							expanded
							native
							@input="Preview"
							accept=".jpg,.png,.bmp"
							validation-message="Please upload a valid image.">
							<div class="file is-boxed has-name">
								<div class="file-label">
									<div class="file-cta">
										<div class="file-icon">
											<o-icon icon="upload" size="is-large"></o-icon>
										</div>
										<div class="file-label">
											<p>Drop your .jpg .png .bmp to upload</p>
										</div>
									</div>
									<div class="file-name" style="min-width: 100%" v-show="file.name">
										{{ file?.name }}
									</div>
								</div>
							</div>
						</o-upload>
					</o-field>
					<o-field label="Preview">
						<figure class="image is-flex">
							<image-component
								:name="typeInfo.Src"
								:alt="typeInfo.Name"
								size="13"
								:data="preview"></image-component>
						</figure>
					</o-field>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<o-field label="Rapid Spec Detail" v-if="typeInfo.StyleTypeInfo">
						<o-input
							v-model="typeInfo.StyleTypeInfo.SpecDetail"
							type="textarea"
							style="resize: vertical; min-height: 80px"></o-input>
					</o-field>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<div class="level">
						<div class="level-left">
							<div class="level-item">
								<h4 class="title is-4">Materials</h4>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<o-button class="is-right" @click="AddMaterial" icon-left="plus">
									Add Material
								</o-button>
							</div>
						</div>
					</div>
					<div class="columns">
						<div class="column is-flex is-flex-wrap-wrap">
							<image-previewer :data="materials" @Remove="RemoveMaterial"></image-previewer>
						</div>
					</div>
				</div>
			</div>
			<div class="columns" v-if="typeInfo.GrilleTypeId === 5">
				<div class="column">
					<div class="level">
						<div class="level-left">
							<div class="level-item">
								<h4 class="title is-4">Sizes</h4>
							</div>
						</div>
						<div class="level-right">
							<div class="level-item">
								<o-button class="is-right" @click="AddSize" icon-left="plus">Add Size</o-button>
							</div>
						</div>
					</div>
					<div class="columns" v-for="(size, i) in typeInfo.StockSizes" :key="i">
						<div class="column">
							<o-field label="Height">
								<o-input v-model="size.Height"></o-input>
							</o-field>
						</div>
						<div class="column">
							<o-field label="Length">
								<o-input v-model="size.Length"></o-input>
							</o-field>
						</div>
						<div class="column">
							<o-field label="Price">
								<o-input v-model="size.Price"></o-input>
							</o-field>
						</div>
						<div class="column">
							<o-field label="Weight">
								<o-input v-model="size.Weight"></o-input>
							</o-field>
						</div>
						<div class="column">
							<o-field label="Include clips">
								<o-checkbox v-model="size.IncludeClip"></o-checkbox>
							</o-field>
							<o-field label="Clip price" v-if="size.IncludeClip">
								<o-input v-model="size.ClipPrice"></o-input>
							</o-field>
						</div>
						<div class="column">
							<o-field label="VariantId">
								<p>{{ size.VariantId }}</p>
							</o-field>
						</div>
						<div class="column" style="margin: auto auto">
							<div class="is-pulled-right">
								<o-button variant="danger" size="is-small" icon-left="times" @click="RemoveSize(i)">
									Remove
								</o-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<o-button :disabled="saving" @click="Save">Save</o-button>
				<div v-if="saving">This process could take up to a minute or two.</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject, nextTick } from "vue";
	import { image, imagePreviewer } from "@/components";
	import { Selector } from "@/components/modal";
	export default {
		components: {
			"image-component": image,
			"image-previewer": imagePreviewer
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			var Id = global.$route.params.Id;
			const options = ref({});
			const loading = ref(true);
			const saving = ref(false);
			const typeInfo = ref({ GrilleTypeId: 1 });
			const materials = ref([]);
			const patternId = ref();
			const file = ref({});
			const preview = ref();

			const isLinear = computed(() => {
				return typeInfo.value?.GrilleTypeId === 1;
			});
			const isPerforated = computed(() => {
				return typeInfo.value?.GrilleTypeId === 2;
			});
			const selectableMaterials = computed(() => {
				return options.value.materials.filter(
					(material) => !materials.value.some((selected) => material.Id === selected.Id)
				);
			});

			const GetInfo = async () => {
				if (Id > 0) {
					try {
						var response = await $http.get("/api/grilleStyleType/" + Id);
						typeInfo.value = response.data;
						file.value.name = typeInfo.value?.Src;

						await GetInfoDetail();
						await GetStockSize();
					} catch (err) {
						console.error(err);
					}
				} else {
					loading.value = false;
				}
			};

			const GetInfoDetail = async () => {
				if (Id > 0) {
					try {
						var response = await $http.get("/api/grilleStyleType/" + Id + "/info");
						typeInfo.value.StyleTypeInfo = response.data;
						return response;
					} catch (err) {
						console.error(err);
					}
				}
			};

			const GetStockSize = async () => {
				try {
					var response = await $http.get("/api/grilleStyleType/" + Id + "/stock/sizes");
					typeInfo.value.StockSizes = response.data;
				} catch (err) {
					console.error(err);
				}
			};

			const GetGrillePatterns = async () => {
				try {
					const response = await $http.get("/api/pattern");
					options.value.patterns = response.data;
					patternId.value = options.value.patterns.filter((x) => x.StyleId == Id)[0]?.Id;
				} catch (err) {
					console.error(err);
				}
			};
			const GetGrilleTypes = async () => {
				try {
					const response = await $http.get("/api/grilleType/all");
					options.value.grilleTypes = response.data;
				} catch (err) {
					console.error(err);
				}
			};

			const GetMaterialStyles = async () => {
				if (Id > 0) {
					try {
						var response = await $http.get("/api/grilleStyleType/" + Id + "/material");
						materials.value = response.data;
					} catch (err) {
						console.error(err);
					}
				}
			};

			const GetMaterials = async () => {
				try {
					var response = await $http.get("/api/grilleMaterialType/all");
					options.value.materials = response.data;
				} catch (err) {
					console.error(err);
				}
			};

			const AddMaterial = () => {
				global.$oruga.modal.open({
					parent: this,
					component: Selector,
					trapFocus: true,
					active: true,
					props: {
						title: "Select a Style",
						multi: true,
						data: selectableMaterials.value
					},
					events: {
						selected: (result) => {
							materials.value.push(...result);
						}
					}
				});
			};

			const RemoveMaterial = (result) => {
				materials.value.splice(result.index, 1);
			};

			const AddSize = () => {
				if (!typeInfo.value.StockSizes) {
					typeInfo.value.StockSizes = [];
				}
				typeInfo.value.StockSizes.push({ StyleId: Id });

				$success("New size add to the bottom of the list");
			};
			const RemoveSize = (i) => {
				typeInfo.value.StockSizes.splice(i, 1);
			};

			const Preview = async (e) => {
				setTimeout(async () => {
					var formData = new FormData();
					formData.append("file", file.value);
					try {
						var response = await $http.post("/api/image/preview/base64", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						preview.value = response.data;
					} catch (err) {
						console.error(err);
					}
				}, 100);
			};

			const TestValidation = () => {
				if (!typeInfo.value.StockSizes.every((x) => x.Length > 0 && x.Height > 0)) {
					$error("Stock sizes must be greater than 0");
					return false;
				}

				return true;
			};

			const Save = async () => {
				if (TestValidation()) {
					saving.value = true;
					var formData = new FormData();
					formData.append("file", file.value);
					formData.append("body", JSON.stringify(typeInfo.value));
					formData.append("materials", JSON.stringify(materials.value));
					formData.append("patternId", patternId.value);
					try {
						var response = await $http.post("/api/GrilleStyleType", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						$success("Update successful!");
						typeInfo.value.ProductId = response.data.ProductId;
						typeInfo.value.StockSizes = response.data.StockSizes;
						saving.value = false;
						if (Id == 0) {
							Id = response.data.Id;
							global.$router.push({ name: "adminGrilleStyleType", params: { Id: response.data.Id } });
						}
					} catch (err) {
						saving.value = false;
						console.error(err);
					}
				}
			};

			onMounted(async () => {
				try {
					const promises = [
						GetInfo(),
						GetGrilleTypes(),
						GetGrillePatterns(),
						GetMaterials(),
						GetMaterialStyles()
					];
					await Promise.all(promises);
					loading.value = false;
				} catch (err) {
					$error("Failed to load. Try refreshing the page.");
				}
			});

			return {
				options,
				loading,
				saving,
				typeInfo,
				materials,
				patternId,
				file,
				preview,
				isLinear,
				isPerforated,
				Preview,
				Save,
				AddMaterial,
				RemoveMaterial,
				AddSize,
				RemoveSize
			};
		}
	};
</script>
