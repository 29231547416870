<template>
    <div class="section">
        <div class="container">
            <div class="level">
                <div class="level-left">
                    <h2 class="title is-2">Grille Types</h2>
                </div>
                <div class="level-right">
                    <o-button class="is-right" @click="Add" icon-left="plus">Add</o-button>
                </div>
            </div>
            <div>
                <p class="mb-5">
                    You can drag and drop when the list is not being filtered.
                </p>
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <o-switch v-model="hideDeleted">Hide Deleted</o-switch>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item" v-show="hasFilter">
                            <o-button variant="warning" @click="ResetFilters">Reset Filters</o-button>
                        </div>
                    </div>
                </div>
                <Types name="GrilleType" :filters="{ hideDeleted: hideDeleted }"></Types>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import { Types } from "@/components/report";
    export default {
        components: {
            Types
        },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const hideDeleted = ref(false);

            const Add = () => {
                global.$router.push({ name: 'adminGrilleType', params: { Id: 0 } });
            };

            const hasFilter = computed(() => { return hideDeleted.value !== false });
            const ResetFilters = () => {
                hideDeleted.value = false;
            };

            onMounted(() => {
                //
            });

            return {
                hideDeleted,
                hasFilter,
                Add,
                ResetFilters
            };
        }
    }

</script>