<template>
    <div class="section">
        <div class="container" v-if="loading">
            <loading></loading>
        </div>
        <div class="container" v-else>
            <div class="level">
                <div class="level-left">
                    <h2 class="title is-2">{{typeInfo.Name}}</h2>
                </div>
                <div class="level-right">
                    <o-field>
                        <o-switch v-model="typeInfo.IsDeleted">Deleted</o-switch>
                        <template v-slot:message>
                            <span v-show="typeInfo.IsDeleted" style="color:indianred">This will not show on the rfq.</span>
                        </template>
                    </o-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <o-field label="Name">
                        <o-input v-model="typeInfo.Name" maxlength="128"></o-input>
                    </o-field>
                </div>
                <div class="column">
                    <o-field label="Image">
                        <o-upload v-model="file" drag-drop expanded native @input="Preview"
                                  accept=".jpg,.png,.bmp"
                                  validation-message="Please upload a valid image.">
                            <div class="file is-boxed has-name">
                                <div class="file-label">
                                    <div class="file-cta">
                                        <div class="file-icon">
                                            <o-icon icon="upload" size="is-large"> </o-icon>
                                        </div>
                                        <div class="file-label">
                                            <p>
                                                Drop your .jpg .png .bmp to upload
                                            </p>
                                        </div>
                                    </div>
                                    <div class="file-name" style="min-width:100%" v-show="file.name">
                                        {{file?.name}}
                                    </div>
                                </div>
                            </div>
                        </o-upload>
                    </o-field>
                    <o-field label="Preview">
                        <figure class="image is-flex">
                            <image-component :name="typeInfo.Src" :alt="typeInfo.Name" size="13" :data="preview"></image-component>
                        </figure>
                    </o-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <o-field v-if="typeInfo.FrameTypeInfo" label="Rapid Spec Detail">
                        <o-input v-model="typeInfo.FrameTypeInfo.SpecDetail" type="textarea" style="resize:vertical; min-height:80px;"></o-input>
                    </o-field>
                </div>
            </div>
            <div>
                <o-button :disabled="saving" @click="Save">Save</o-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, nextTick } from "vue";
    import { image } from "@/components";
    export default {
        components: {
            "image-component": image
        },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            var Id = global.$route.params.Id;
            const loading = ref(true);
            const saving = ref(false);
            const typeInfo = ref({});
            const file = ref({});
            const preview = ref();

            const GetInfo = async () => {
                if (Id > 0) {
                    try {
                        var response = await $http.get("/api/grilleFrameType/" + Id);
                        typeInfo.value = response.data;
                        file.value.name = typeInfo.value?.Src;
                        loading.value = false;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                else {
                    loading.value = false;
                }
            };

            const GetInfoDetail = async () => {
                if (Id > 0) {
                    try {
                        var response = await $http.get("/api/grilleFrameType/" + Id + "/info");
                        typeInfo.value.FrameTypeInfo = response.data;
                        return response;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
            }

            const Preview = async (e) => {
                setTimeout(async () => {
                    var formData = new FormData();
                    formData.append('file', file.value);
                    try {
                        var response = await $http.post("/api/image/preview/base64", formData, { headers: { "Content-Type": "multipart/form-data" } });
                        preview.value = response.data;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }, 100);
            };

            const Save = async () => {
                saving.value = true;
                var formData = new FormData();
                formData.append('file', file.value);
                formData.append('body', JSON.stringify(typeInfo.value));
                try {
                    var response = await $http.post("/api/GrilleFrameType", formData, { headers: { "Content-Type": 'multipart/form-data' } });
                    $success("Update successful!");
                    saving.value = false;
                    if (Id == 0) {
                        Id = response.data.Id;
                        global.$router.push({ name: "adminGrilleFrameType", params: { Id: response.data.Id } });
                    }
                }
                catch (err) {
                    saving.value = false;
                    console.error(err);
                }
            };

            onMounted(() => {
                GetInfo();
            });

            return {
                loading,
                saving,
                typeInfo,
                file,
                preview,
                Preview,
                Save
            };
        }
    }

</script>