<template>
    <div class="section">
        <div class="container" v-if="loading">
            <loading></loading>
        </div>
        <div class="container" v-else>
            <div class="level">
                <div class="level-left">
                    <h2 class="title is-2">{{pattern.Name}}</h2>
                </div>
                <div class="level-right">

                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <o-field label="Name">
                        <o-input v-model="pattern.Name" maxlength="128"></o-input>
                    </o-field>
                </div>
                <div class="column">
                    <o-field label="Style" v-if="options.styles">
                        <o-select v-model="pattern.StyleId" placeholder="None">
                            <option :key="undefined" :value="undefined" hidden disabled>None</option>
                            <option v-for="option in options.styles" :key="option.Id" :value="option.Id">{{option.Name}} <span v-if="option.IsDeleted">(Deleted)</span></option>
                        </o-select>
                    </o-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <h4 class="title is-4">Details</h4>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <o-button class="is-right" @click="AddDetail" icon-left="plus">Add Detail</o-button>
                            </div>
                        </div>
                    </div>
                    <div class="columns" v-for="(detail, index) in details" :key="index">
                        <div class="column">
                            <o-field label="Percent Open">
                                <o-input v-model="detail.PercentOpen" type="number" min="0" max="100" step="1"></o-input>
                            </o-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <h4 class="title is-4">Sizes</h4>
                            </div>
                        </div>
                        <div class="level-right">
                            <div class="level-item">
                                <o-button class="is-right" @click="AddSize" icon-left="plus">Add Size</o-button>
                            </div>
                        </div>
                    </div>
                    <div class="columns" v-for="(size, index) in sizes" :key="index">
                        <div class="column">
                            <o-field label="Length">
                                <o-input v-model="size.Length" type="number" min="0" step="0.0001"></o-input>
                            </o-field>
                        </div>
                        <div class="column">
                            <o-field label="Height">
                                <o-input v-model="size.Height" type="number" min="0" step="0.0001"></o-input>
                            </o-field>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <o-button :disabled="saving" @click="Save">Save</o-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, nextTick } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            var Id = global.$route.params.Id;
            const loading = ref(true);
            const saving = ref(false);
            const options = ref({});
            const pattern = ref({});
            const details = ref([]);
            const sizes = ref([]);

            const GetPattern = async () => {
                if (Id > 0) {
                    try {
                        var response = await $http.get("/api/Pattern/" + Id);
                        pattern.value = response.data;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                else {
                    loading.value = false;
                }
            };

            const GetPatternDetails = async () => {
                if (Id > 0) {
                    try {
                        var response = await $http.get("/api/Pattern/" + Id + "/details");
                        details.value = response.data;
                        loading.value = false;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                else {
                    loading.value = false;
                }
            };
            const GetPatternSizes = async () => {
                if (Id > 0) {
                    try {
                        var response = await $http.get("/api/Pattern/" + Id + "/sizes");
                        sizes.value = response.data;
                        loading.value = false;
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                else {
                    loading.value = false;
                }
            };

            const GetStyles = async () => {
                try {
                    var response = await $http.get("/api/GrilleStyleType/all");
                    options.value.styles = response.data;
                }
                catch (err) {
                    console.error(err);
                }
            };

            const AddDetail = () => {
                details.value.push({ Id: 0, PatternId: Id });
            };

            const AddSize = () => {
                sizes.value.push({ Id: 0, PatternId: Id });
            };

            const Save = async () => {
                if (!pattern.value.StyleId) {
                    $error("You must choose a style.");
                    return;
                }

                saving.value = true;
                try {
                    var response = await $http.post("/api/Pattern", { pattern: pattern.value, details: details.value, sizes: sizes.value });
                    $success("Update successful!");
                    saving.value = false;
                    if (Id == 0) {
                        Id = response.data.Id;
                        global.$router.push({ name: "adminGrillePatternType", params: { Id: response.data.Id } });
                    }
                }
                catch (err) {
                    saving.value = false;
                    console.error(err);
                }
            };

            onMounted(async () => {
                try {
                    var promises = [GetPattern(), GetPatternDetails(), GetPatternSizes(), GetStyles()];
                    await Promise.all(promises);
                    loading.value = false;
                }
                catch (err) { $error("Failed to load. Try refreshing the page.") }
            });

            return {
                loading,
                saving,
                options,
                pattern,
                details,
                sizes,
                Save,
                AddDetail,
                AddSize
            };
        }
    }

</script>