<template>
	<div class="section">
		<div class="container">
			<div class="level">
				<div class="level-left">
					<h2 class="title is-2">Style Types</h2>
				</div>
				<div class="level-right">
					<o-button class="is-right" @click="Add" icon-left="plus">Add</o-button>
				</div>
			</div>
			<div>
				<p class="mb-5">You can drag and drop when the list is not being filtered.</p>
				<div class="level">
					<div class="level-left">
						<div class="level-item">
							<o-switch v-model="hideDeleted">Hide Deleted</o-switch>
						</div>
						<div class="level-item">
							<o-select v-model="grilleTypeId" placeholder="All Grille Types">
								<option :key="undefined" :value="undefined">All Grille Types</option>
								<option v-for="option in options.grilleTypes" :key="option.Id" :value="option.Id">
									{{ option.Name }}
									<span v-if="option.IsDeleted">&nbsp;(Deleted)</span>
								</option>
							</o-select>
						</div>
					</div>
					<div class="level-right">
						<div class="level-item" v-show="hasFilter">
							<o-button variant="warning" @click="ResetFilters">Reset Filters</o-button>
						</div>
					</div>
				</div>
				<Types
					name="GrilleStyleType"
					size="4"
					:filters="{ hideDeleted: hideDeleted, props: { GrilleTypeId: grilleTypeId } }"></Types>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { Types } from "@/components/report";
	export default {
		components: {
			Types
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const options = ref({});
			const hideDeleted = ref(true);
			const grilleTypeId = ref();

			const Add = () => {
				global.$router.push({ name: "adminGrilleStyleType", params: { Id: 0 } });
			};

			const GetGrilleTypes = async () => {
				try {
					const response = await $http.get("/api/grilleType/all");
					options.value.grilleTypes = response.data;
				} catch (err) {
					console.error(err);
				}
			};

			const hasFilter = computed(() => {
				return hideDeleted.value !== false || !!grilleTypeId.value;
			});
			const ResetFilters = () => {
				hideDeleted.value = false;
				grilleTypeId.value = null;
			};

			onMounted(() => {
				GetGrilleTypes();
			});

			return {
				options,
				hideDeleted,
				grilleTypeId,
				hasFilter,
				Add,
				ResetFilters
			};
		}
	};
</script>
