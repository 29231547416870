<template>
    <div class="section">
        <div class="container">
            <div class="level">
                <div class="level-left">
                    <h2 class="title is-2">Grille Applications</h2>
                </div>
                <div class="level-right">
                    <o-button class="is-right" @click="Add" icon-left="plus">Add</o-button>
                </div>
            </div>
            <div>
                <p class="mb-5">

                </p>
                <o-table :data="rows">
                    <o-table-column field="Name" label="Name" v-slot="props" sortable>
                        {{props.row.Name}} <span style="color:indianred" v-if="props.row.IsDeleted">deleted</span>
                    </o-table-column>
                    <o-table-column position="right" v-slot="props">
                        <o-button @click="Edit(props.row)">Edit</o-button>
                    </o-table-column>
                </o-table>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const loading = ref(true);
            const applications = ref([]);
            const rows = computed(() => {
                return applications.value;
            });

            const GetApplications = async () => {
                try {
                    const response = await $http.get("/api/applicationType/all");
                    applications.value = response.data;
                    loading.value = false;
                    return response;
                }
                catch (err) {
                    loading.value = false;
                    return Promise.reject(err);
                }
            };

            const Add = () => {
                global.$router.push({ name: 'adminApplicationType', params: { Id: 0 } });
            };

            const Edit = (row) => {
                global.$router.push({ name: 'adminApplicationType', params: { Id: row.Id } });
            };

            onMounted(() => {
                GetApplications();
            });

            return {
                rows,
                Add,
                Edit
            };
        }
    }

</script>